<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <CustomerNavbar></CustomerNavbar>
      </div>
      <div class="col-md-9">
        <div class="panel panel-default">
          <div class="panel-heading dash-head">Ordered Information</div>
          <div class="panel-body">
            <table class="table">
              <thead class="text-center">
                <th>Order Date</th>
                <th>Order Id</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Status</th>
                <th style="text-align: right">Action</th>
              </thead>
              <tbody class="text-left">
                <tr v-for="row in customerSummery.orders" :key="row.id">
                  <td>{{ row.order_date }}</td>
                  <td>{{ row.orderID }}</td>
                  <td>{{ row.order_items[0].quantity }}</td>
                  <td>{{ row.total_amount }}</td>
                  <td :class="row.order_status === 0 ? 'cancel' :
                    row.order_status === 1 ? 'pending' :
                      row.order_status === 2 ? 'confirm' :
                        row.order_status === 3 ? 'hold' :
                          row.order_status === 4 ? 'shipped' :
                            row.order_status === 5 ? 'delivered' :
                              row.order_status === 6 ? 'partial' : ''">
                    <span>
                      {{ row.order_status === 0 ? 'cancel' :
                        row.order_status === 1 ? 'Pending' :
                          row.order_status === 2 ? 'confirm' :
                            row.order_status === 3 ? 'Hold' :
                              row.order_status === 4 ? 'shipped' :
                                row.order_status === 5 ? 'delivered' :
                                  row.order_status === 6 ? 'partial delivered' : ''
                      }}
                    </span>

                  </td>
                  <td style="text-align: right">
                    <button class="btn btn-primary" type="button" @click="addCart(row)">
                      Add to Cart
                    </button>
                    &nbsp;
                    <button class="btn btn-success" type="button" @click="viewDetails(row.id)">
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CustomerNavbar from './CustomerNavbar'
export default {
  name: "Order",
  components: {
    CustomerNavbar
  },
  computed: {
    ...mapGetters(["me", "customerSummery", "loading", "singleProduct"]),
  },
  methods: {
    viewDetails(orderId) {
      this.$router.push('/order_details/' + orderId).catch(() => { })
    },
    cancelOrder(orderid) {
      this.$store.dispatch('cancelOrder', orderid);
      this.$store.dispatch('getcustomerSummery');
      this.$store.dispatch('getcustomerSummery');
      //this.$toasted.success('Successfully Canceled.');
    },
    addCart(product) {
      this.$store.dispatch('getOrderDetails', product.id);
      setTimeout(() => {
        // console.log('product =555555 ', this.$store.getters.orderDetails);
        this.orderDetails = this.$store.getters.orderDetails;
        this.$store.dispatch('getSingleProductClear');
        this.orderDetails.order_items.forEach((item) => {
          this.$store.dispatch('getSingleProduct', item.product_id)
        })
      }, 1000)

      //var parsedObj = JSON.parse(JSON.stringify(this.singleProduct))

      /*if(pro.product_type === 1){
                        pro.qty = 1;
                        pro.cartImage = pro.product_images[0].image;
                        pro.product_price[0].offer_price > 0
                            ? pro.unitPrice = pro.product_price[0].offer_price
                            : pro.unitPrice = pro.product_price[0].price;

                        this.$store.dispatch("addToBag", pro);
                        // console.log('product from component', pro)
                    }else {
                        // console.log("===Else>>>", pro);
                        pro.qty = 1;
                        pro.cartImage = pro.product_images[0].image;
                        pro.min_offer_price > 0
                            ? pro.unitPrice = pro.min_offer_price
                            : pro.unitPrice = pro.min_price;

                        this.$store.dispatch("addToBag", pro);
                    }*/

      setTimeout(() => {

        // console.log("Single product ====>", this.singleProduct)
        this.singleProduct.forEach((pro) => {
          if (pro.product_type === 1 || pro.product_type === 3) {
            pro.qty = 1;
            pro.cartImage = pro.product_images[0].image;
            pro.product_price[0].offer_price > 0
              ? pro.unitPrice = pro.product_price[0].offer_price
              : pro.unitPrice = pro.product_price[0].price;

            this.$store.dispatch("addToBag", pro);

          } else {
            // console.log("===Else>>>", pro);
            pro.qty = 1;
            pro.cartImage = pro.product_images[0].image;
            pro.min_offer_price > 0
              ? pro.unitPrice = pro.min_offer_price
              : pro.unitPrice = pro.min_price;

            this.$store.dispatch("addToBag", pro);
          }
        })
      }, 3000)
    },
  },

}

</script>